<template>
  <modal
    v-bind="modalData"
    class="modal-bg-gray"
    @close="close"
  >
    <page :data="pageData" :is-child="true">
      <template #beforecmsleft v-if="ready">
        <div class="map w-full xl:w-3/4 xl:mx-5" id="map" :style="mapStyles">
        </div>
      </template>
    </page>
  </modal>
</template>

<script>
import ModalCMSPage from '../../base/ModalCMSPage'
export default {
  extends: ModalCMSPage,
  data () {
    return {
      mapHeight: '300px'
    }
  },
  watch: {
    config (config) {
      if (!config.Page) return
      setTimeout(() => {
        if (!this.isMobile) this.calcMapHeight()
        this.$nextTick(this.initMap)
      }, 100)
    }
  },
  methods: {
    initMap () {
      this.leafletMap({
        DisableScroll: this.config.disableScroll,
        Latitude: this.config.Latitude,
        Longitude: this.config.Longitude,
        Name: this.config.Page.Title
      })
    },
    calcMapHeight () {
      const body = document.querySelector('.modal-card-body')
      if (!this.isMobile && body) {
        const bodyRect = body.getBoundingClientRect()
        this.mapHeight = bodyRect.height + 'px'
      }
    }
  },
  computed: {
    mapStyles () {
      return {
        zIndex: 0,
        height: this.mapHeight + ' !important',
        ...this.mapMobileStyles
      }
    },
    mapMobileStyles () {
      if (!this.isMobile) return null
      return {
        width: 'calc(100% + 40px)',
        margin: '-20px -20px 0'
      }
    },
    ready () {
      return this.config.Page
    }
  }
}
</script>

<style>

</style>
